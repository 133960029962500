import Ajv from 'ajv';
import { JSONSchemaBridge } from 'uniforms-bridge-json-schema';
const ajv = new Ajv({ allErrors: true, useDefaults: true, $data: true });
ajv.addKeyword('uniforms');

function createValidator(schema) {
    const validator = ajv.compile(schema);
    return (model) => {
        validator(model);
        return validator.errors?.length ? { details: validator.errors } : null;
    };
}

export function getSchema() {
    const schema = {
        title: "MSP Company Schema",
        type: "object",
        properties: {
            _id: { type: "string", title: "ID", default: "0",uniforms: { disabled: true } },  // Hide this field },
            company_id: { type: "integer", title: "Company ID",uniforms: { disabled: true } },
            countries: {
                type: "array",
                title: "Countries",
                items: {
                    type: "string",
                },
            },
//            date_created: { type: "string", format: "date-time", title: "Date Created" }, // ISODate as date-time
            email_domains: {
                type: "array",
                title: "Email Domains",
                items: {
                    type: "string",
                },
            },
            name: { type: "string", title: "Company Name" },
            root_domains: {
                type: "array",
                title: "Root Domains",
                items: {
                    type: "string",
                },
            },
            sectors: {
                type: "array",
                title: "Sectors",
                items: {
                    type: "string",
                },
            },
            tags: {
                type: "array",
                title: "Tags",
                items: {
                    type: "string",
                },
            },
            website: { type: "string", format: "uri", title: "Website" }, // URI format for website
            apikey: { type: "string", title: "API Key" },
            apisecret: { type: "string", title: "API Secret" },
//            date_updated: { type: "string", format: "date-time", title: "Date Updated" }, // ISODate as date-time
            hidden: { type: "boolean", title: "Hidden" },
        },
        required: [
            "company_id",
            "countries",
            "email_domains",
            "name",
            "root_domains",
            "sectors",
            "website",
            "apikey",
            "apisecret",
            "ransomware_count",
            "date_updated",
            "risk_score",
            "hidden",
        ],
    };
    const validator = createValidator(schema);
    return new JSONSchemaBridge({ schema, validator });
}
