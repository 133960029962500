import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCsv, faFileCode } from '@fortawesome/free-solid-svg-icons';

const ExportData = ({ filename, topic, data }) => {

    const exclude = [
        'id', 'company_id', 'last_updated_timestamp', 'last_scan_timestamp', 'cred_id',
        'spf_enabled', 'dmarc_enabled', 'spf_strong', 'dmarc_strong', 'correction', 'itemx',
        'uuid', 'password', 'token', 'refreshToken', 'corrections','items'
    ];

    const filteredData = (data) => 
        data.map(item => {
            return Object.keys(item).reduce((newItem, key) => {
                if (!exclude.includes(key)) {
                    newItem[key] = item[key];
                }
                return newItem;
            }, {});
        });

    // Function to generate a filename with the current date and provided name
    const make_filename = (extension) => {
        const timestamp = new Date().toISOString().split('T')[0]; // Get current date in YYYY-MM-DD format
        const name = `${timestamp}-${filename}-${topic}`
            .replace(/\s+/g, '_') // Replace spaces with underscores
            .replace(/[/\\?%*:|"<>[\x7F\x00-\x1F]/g, '-'); // Replace invalid filename characters with a hyphen
        return `${name}.${extension}`;
    };

    // Function to export data as CSV with all headers and fields double-quoted
    const export_csv = () => {
        if (!data.length) return;

        const headers = Object.keys(data[0]).filter(key => !exclude.includes(key));

        // Quote all fields (headers and data)
        const csvContent = [
            headers.map(header => `"${header}"`).join(','), // Double-quoted CSV Headers
            ...data.map(row =>
                headers.map(field => `"${row[field]}"`).join(',')
            ) // Double-quoted CSV Rows
        ].join('\n');

        const blob = new Blob([csvContent], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.download = make_filename('csv'); // Use the dynamic filename with CSV extension
        link.href = url;
        link.click();
    };



    // Function to export data as JSON
    const export_json = () => {
        if (!data.length) return;

        const jsonContent = JSON.stringify(filteredData(data), null, 2); // Pretty-print JSON
        const blob = new Blob([jsonContent], { type: 'application/json' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.download = make_filename('json'); // Use the dynamic filename with JSON extension
        link.href = url;
        link.click();
    };

    return (
        <>
            {/* Icon for CSV export */}
            <FontAwesomeIcon
                icon={faFileCsv}
                style={{ cursor: 'pointer', marginRight: '10px', fontSize: '1.5em' }}
                title="Download CSV"
                onClick={export_csv}
            />

            {/* Icon for JSON export */}
            <FontAwesomeIcon
                icon={faFileCode}
                style={{ cursor: 'pointer', marginRight: '10px', fontSize: '1.5em' }}
                title="Download JSON"
                onClick={export_json}
            />
        </>);
};

export default ExportData;
