// apiClient.js
const apiHost = window.env.API_HOST;

const restHandler = async (method, url, data = null, headers = {}) => {
  try {
    const options = {
      method,
      headers: {
      // Only set Content-Type if the data is not FormData
      ...(data instanceof FormData ? {} : { 'Content-Type': 'application/json' }),
      ...headers, // Allow overriding or adding custom headers
      },
      credentials: 'include', // Include cookies with the request
    };

    if (data) {
      // If data is FormData, send it as is; otherwise, stringify JSON data
      options.body = data instanceof FormData ? data : JSON.stringify(data);
    }

    const response = await fetch(apiHost + url, options);

    if (!response.ok) {
      const errorData = await response.json(); // Parse error response
      throw new Error(errorData.message || errorData.detail || `API request failed with status ${response.status}`);
    }

    const result = await response.json(); // Parse response as JSON
    return result; // Return the parsed data
  } catch (error) {
    throw error; // Re-throw the error for the caller
  }
};

export default restHandler;