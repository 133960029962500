import React, { useContext, useState, useEffect } from "react";
import { useCookies } from 'react-cookie';
import { Link, Outlet } from 'react-router-dom';
import useAuth from './hooks/useAuth';
import { CompanyContext } from './context/CompanyContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar,faCaretDown } from '@fortawesome/free-solid-svg-icons';
import './Layout.css'

// Define the last update date for the welcome page
const WELCOME_PAGE_UPDATE_DATE = '2024-11-19'; // Adjust this date when the welcome page is updated

const Layout = () => {

    const { auth } = useAuth();
    const { company } = useContext(CompanyContext);
    const isAuthenticated = !(auth?.username == null);
    const isAdmin = auth?.roles?.includes("admin");
    const isMSP = auth?.roles?.includes("msp");

    const [cookies, setCookie] = useCookies(['welcome_page_seen']);
    const [showWelcomePage, setShowWelcomePage] = useState(false);
    const [isAdminMenuOpen, setIsAdminMenuOpen] = useState(false);

    const toggleAdminMenu = () => {
        setIsAdminMenuOpen(!isAdminMenuOpen);
    };

    useEffect(() => {
        const cookieDate = cookies.welcome_page_seen;
        // If there's no cookie or the cookie date is before the update date, show the welcome page
        if (!cookieDate || new Date(cookieDate) < new Date(WELCOME_PAGE_UPDATE_DATE)) {
            setShowWelcomePage(true);
        }
    }, [cookies, setCookie]);

    return (
        <div>
            <nav className="top-nav">
                <div className="logo-container">
                    <Link to="/">
                        <img src='/assets/images/tara-logo.png' alt="Logo" className="logo" />
                    </Link>
                </div>
                {isAuthenticated && (<ul className="nav-list">
                    <li className="nav-item"><Link to="/">Home</Link></li>
                    {company && <li className="nav-item"><Link to="/dashboard">Dashboard</Link></li>}
                    <li className="nav-item"><Link to="/welcome">Welcome&nbsp;{showWelcomePage && <FontAwesomeIcon icon={faStar} className="new-icon" />}</Link></li>
                    <li className="nav-item"><Link to="/eula">EULA</Link></li>
                    {(isAdmin || isMSP) && (<>
                        <li className="nav-item"><Link to="/svintel">SV Intel</Link></li>
                        <li className="nav-item"><Link to="/admin/menu">Admin</Link></li>
                    </>)}
                    <li className="nav-item"><Link to="/logout">Logout</Link></li>
                </ul>
                )}
            </nav>
            <div className="content">
                <Outlet />
            </div>
        </div>
    );
};

export default Layout;