import React, { useState, useEffect } from 'react';
import { useNavigate} from 'react-router-dom';
import { AutoForm } from 'uniforms-bootstrap5';
import { getSchema } from './MspSchema';
import restHandler from './apiClient';
import { notice_success, notice_error } from './utils/toast_helpers';

export function MspForm({ model, onSubmit }) {
    const [companyList, setCompanyList] = useState([]);
    const [schema, setSchema] = useState(null);
    const navigate = useNavigate();
    const [isFormVisible, setFormVisible] = useState(true);

    const onSave = async (model) => {
        try {
            const { _id,created_at,updated_at, ...rest } = model;
            const data = {
                _id: model._id?.toString() || '0',
                topic: "DbMsp",
                updates: rest
            };
            const result = await restHandler('PUT', '/api/update-or-insert-document', data);
            notice_success(result.message);
        } catch (err) {
            notice_error(err.message || err.detail || 'Error fetching data');
        } finally {
            setFormVisible(false);
            navigate('/admin/msps', { replace: true });
        }
    }

    // Simulate fetching the company list based on a condition (e.g., user role)
    useEffect(() => {
        // Simulate an API call or condition
        const userRole = 'admin'; // Example condition
        let companies = [];

        if (userRole === 'admin') {
            companies = ['Company A', 'Company B', 'Company C'];
        } else {
            companies = ['Company X', 'Company Y'];
        }

        setCompanyList(companies);
        setSchema(getSchema(companies)); // Update schema with the dynamic company list
    }, []);

    if (!schema) {
        return <div>Loading...</div>; // Loading state while the schema is being built
    }

    return (
        <>
        {isFormVisible&& (<AutoForm
            schema={schema}
            model={model}
            onSubmit={(model) => onSave(model)}
        />)}
        </>
    );
}

export default MspForm;