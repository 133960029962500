import restHandler from './apiClient';

export const fetchCompanies = async () => {
    try {
        const result = await restHandler('GET', '/api/companies');
        return result;
    } catch (error) {
        throw new Error(error.detail || error);
    }
};

export const fetchSummary = async (company_id) => {
    try {
        const result = await restHandler('GET', '/api/company_summary/' + company_id);
        return result;
    } catch (error) {
        throw new Error(error.detail || error);
    }
};

export const fetchFindings = async (company_id, topic) => {
    try {
        const result = await restHandler('GET', '/api/company_findings/' + topic + '/' + company_id);
        return result;
    } catch (error) {
        throw new Error(error.detail || error);
    }
};

export const fetchCompare = async (company_id) => {
    try {
        const result = await restHandler('GET', '/api/company_compare/' + company_id);
        return result;
    } catch (error) {
        throw new Error(error.detail || error);
    }
};

export const fetchSvIntel = async (cve) => {
    try {
        const result = await restHandler('GET', '/api/svintel/' + cve);
        return result;
    } catch (error) {
        throw new Error(error.detail || error);
    }
};

export const fetchVulnerabilities = async (company_id) => {
    try {
        const result = await restHandler('GET', '/api/company_vulnerabilities/' + company_id);
        return result;
    } catch (error) {
        throw new Error(error.detail || error);
    }
};

export const fetchCorrections = async (company_id, topic) => {
    try {
        const results = await restHandler('GET', '/api/corrections/' + topic + '/' + company_id);
        return results;
    } catch (error) {
        throw new Error(error.detail || error);
    }
}

export const fetchMSPs = async () => {
    try {
        const results = await restHandler('GET', '/api/msps/');
        return results;
    } catch (error) {
        throw new Error(error.detail || error);
    }
}

export const fetchCollection = async (topoic) => {
    try {
        const data = { collection_name: topoic }
        const results = await restHandler('POST', '/api/get-collection-data/', data);
        return results;
    } catch (error) {
        throw new Error(error.detail || error);
    }
}

export const acceptEula = async (accepted) => {
    try {
        const results = await restHandler('POST', '/api/accept_eula/', { accepted:accepted});
        return results;
    } catch (error) {
        throw new Error(error.detail || error);
    }
}
