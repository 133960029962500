import React, { useState, useEffect } from 'react';

const CustomAlert = ({ type, message }) => {
    const [visible, setVisible] = useState(true);

    useEffect(() => {
        setVisible(true);
        if (type === 'infox') { // disable timer for now
          const timer = setTimeout(() => setVisible(false), 15000); // Hide after 15 seconds
          return () => clearTimeout(timer); // Clear the timer if the component unmounts
        }
      }, [type,message]);

    if (!visible) return null;

    let alertClass = '';
    switch (type) {
        case 'error':
            alertClass = 'alert-danger';
            break;
        case 'warning':
            alertClass = 'alert-warning';
            break;
        case 'info':
        default:
            alertClass = 'alert-info';
            break;
    }

    return (
        <div className={`alert ${alertClass} alert-dismissible fade show`} role="alert">
            {message}
            <button type="button" className="btn-close" aria-label="Close" onClick={() => setVisible(false)}></button>
        </div>
    );
}

export default CustomAlert;