import React, { useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { Modal, Button } from 'react-bootstrap';
import 'ag-grid-community/styles/ag-grid.css'; // Import ag-Grid CSS
import 'ag-grid-community/styles/ag-theme-quartz.css'; // Import ag-Grid theme
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import { MspForm } from './MspForm';
import Layout from './Layout';
import { fetchCollection } from './FetchData';

function MspList() {
    const [rowData, setRowData] = useState([]);
    const [columnDefs] = useState([
        { headerName: 'ID', field: 'msp_id', cellClass: "ag-left-align", flex: 1 },
        { headerName: 'Name', field: 'name',cellClass: "ag-left-align", flex: 2 },
        { headerName: 'Email', field: 'primary_email',cellClass: "ag-left-align", flex: 1 },
        { headerName: 'Phone', field: 'primary_phone',cellClass: "ag-left-align", flex: 1 },
    ]);

    const [selectedMsp, setSelectedMsp] = useState(null); // State to hold the selected MSP
    const [showModal, setShowModal] = useState(false); // State to control modal visibility

    useEffect(() => {
        fetchCollection('DbMsp').then((results) => {
            setRowData(results);
        });
    },[]);

    // Handle row selection
    const onRowSelected = (event) => {
        setSelectedMsp(event.data); // Set the selected MSP data
        setShowModal(true); // Show the modal
    };

    // Handle form submission
    const handleFormSubmit = (model) => {
        alert(JSON.stringify(model, null, 2)); // Do something with the submitted form data
        setShowModal(false); // Close the modal after submitting
    };

    return (
        <>
        <Layout />
        <div className="ag-theme-quartz-auto-dark" style={{ height: 400, width: '100%' }}>
            <AgGridReact
                rowData={rowData}
                columnDefs={columnDefs}
                onRowClicked={onRowSelected} // Open modal on row click
            />

            {/* Modal to display the form */}
            <Modal size="xl" show={showModal} onHide={() => setShowModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Edit MSP Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedMsp && (
                        <MspForm model={selectedMsp} onSubmit={handleFormSubmit} /> // Pass selected MSP data to the form
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
        </>
    );
}

export default MspList;