import React, { useState, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { Modal, Button } from 'react-bootstrap';
import 'ag-grid-community/styles/ag-grid.css'; // Import ag-Grid CSS
import 'ag-grid-community/styles/ag-theme-quartz.css'; // Import ag-Grid theme
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import { CompanyForm } from './CompanyForm';
import Layout from './Layout';
import { fetchCollection } from './FetchData';


import Loading from './components/Loading';
import CustomAlert from './components/CustomAlert.js';
import { useQuery } from '@tanstack/react-query';
import { fetchCompanies } from './FetchData';
import ExportData from './utils/ExportData';
import useAuth from './hooks/useAuth';

function CompanyList() {
    const { auth } = useAuth();
    const [rowData,setRowData] = useState([]);
    const { data, error, isLoading, isError } = useQuery({ queryKey: ['companies', auth.username], queryFn: () => fetchCompanies() });
    const [columnDefs] = useState([
        { headerName: 'ID', field: 'company_id', cellClass: "ag-left-align", flex: 1 },
        { headerName: 'Name', field: 'name', cellClass: "ag-left-align", flex: 2 },
        { headerName: 'Website', field: 'website', cellClass: "ag-left-align", flex: 1 },
    ]);

    useEffect(() => {
        fetchCollection('DbCompany').then((results) => {
            setRowData(results);
        });
    },[]);

    const [selectedCompany, setSelectedCompany] = useState(null); // State to hold the selected MSP
    const [showModal, setShowModal] = useState(false); // State to control modal visibility

    // Handle row selection
    const onRowSelected = (event) => {
        setSelectedCompany(event.data); // Set the selected MSP data
        setShowModal(true); // Show the modal
    };

    // Handle form submission
    const handleFormSubmit = (model) => {
        alert(JSON.stringify(model, null, 2)); // Do something with the submitted form data
        setShowModal(false); // Close the modal after submitting
    };


    if (isLoading) return <Loading />;
    if (isError) return <CustomAlert type='error' message={error.message} />

    return (
        <>
            <Layout />
            <div className="ag-theme-quartz-auto-dark" style={{ height: 400, width: '100%' }}>
                <AgGridReact
                    rowData={rowData}
                    columnDefs={columnDefs}
                    onRowClicked={onRowSelected} // Open modal on row click
                />

                {/* Modal to display the form */}
                <Modal size="xl" show={showModal} onHide={() => setShowModal(false)} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Company Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {selectedCompany && (
                            <CompanyForm model={selectedCompany} onSubmit={handleFormSubmit} /> // Pass selected MSP data to the form
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowModal(false)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    );
}

export default CompanyList;