import Ajv from 'ajv';
import { JSONSchemaBridge } from 'uniforms-bridge-json-schema';
const ajv = new Ajv({ allErrors: true, useDefaults: true, $data: true });
ajv.addKeyword('uniforms');

const US_STATES = [
  'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA',
  'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD',
  'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ',
  'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC',
  'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'
];

function createValidator(schema) {
  const validator = ajv.compile(schema);
  return (model) => {
    validator(model);
    return validator.errors?.length ? { details: validator.errors } : null;
  };
}

export function getSchema(companyList) {
  const schema = {
    title: 'Managed Service Provider',
    type: "object",
    properties: {
      _id: { type: "string", title: "ID", default: "0",uniforms: { disabled: true } },  // Hide this field },
      msp_id: { type: "integer", title: "Company ID",uniforms: { disabled: true } },
      name: { type: "string",title: "Name", minLength: 10 },
      active: { type: "boolean", title: "Active", default: true },
      allow_customer_access: { type: "boolean", title: "Allow Customer Access", default: false },
      primary_email: {
        type: "string",
        title: "Primary Email",
        format: "email",
      },
      primary_phone: {
        type: "string",
        title: "Primary Phone",
        pattern: "^[0-9]{10}$", // Regex for a basic 10-digit phone number
      },
      primary_address: {
        type: "object",
        title: "Primary Address",
        properties: {
          city: { type: "string", title: "City" },
          state: {
            type: "string",
            title: "State",
            enum: US_STATES,
          },
          zip: {
            type: "string",
            title: "ZIP Code",
            pattern: "^[0-9]{5}$", // Regex for a 5-digit zip code
          },
        },
        required: ["city", "state", "zip"],
      },
      billing_email: {
        type: "string",
        title: "Billing Email",
        format: "email",
      },
      billing_phone: {
        type: "string",
        title: "Billing Phone",
        pattern: "^[0-9]{10}$", // Regex for a basic 10-digit phone number
      },
      billing_address: {
        type: "object",
        title: "Billing Address",
        properties: {
          city: { type: "string", title: "City" },
          state: {
            type: "string",
            title: "State",
            enum: US_STATES,
          },
          zip: {
            type: "string",
            title: "ZIP Code",
            pattern: "^[0-9]{5}$", // Regex for a 5-digit zip code
          },
        },
        required: ["city", "state", "zip"],
      },
    },
    required: ["_id", "name","primary_email", "primary_phone", "primary_address", "billing_email", "billing_phone", "billing_address"],
  };

  const validator = createValidator(schema);

  return new JSONSchemaBridge({ schema, validator });
}


