import React, { useState, useEffect } from 'react';
import { AutoForm } from 'uniforms-bootstrap5';
import { getSchema } from './CompanySchema';

export function CompanyForm({ model, onSubmit }) {
    const [schema, setSchema] = useState(null);

    // Simulate fetching the company list based on a condition (e.g., user role)
    useEffect(() => {
        setSchema(getSchema()); // Update schema with the dynamic company list
    }, []);

    if (!schema) {
        return <div>Loading...</div>; // Loading state while the schema is being built
    }

    return (
        <AutoForm
            schema={schema}
            model={model}
            onSubmit={(model) => alert(JSON.stringify(model, null, 2))}
        />
    );
}

export default CompanyForm;