import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import restHandler from './apiClient';
import { notice_success, notice_error } from './utils/toast_helpers';
import { AutoForm } from 'uniforms-bootstrap5';
import Ajv from 'ajv';
import { JSONSchemaBridge } from 'uniforms-bridge-json-schema';
import CompanyIndustrySelectField from './CompanyIndustrySelectField';
import CheckboxGroup from './CheckBoxGroup'

import Layout from './Layout';
const ajv = new Ajv({ allErrors: true, useDefaults: true, $data: true });
ajv.addKeyword('uniforms');

const domainPattern = "^[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$";
// Updated regular expression to validate each octet (0-255)
const ipPattern = /^((25[0-5]|2[0-4]\d|1\d{2}|[1-9]?\d)\.){3}(25[0-5]|2[0-4]\d|1\d{2}|[1-9]?\d)(-(25[0-5]|2[0-4]\d|1\d{2}|[1-9]?\d\.){3}(25[0-5]|2[0-4]\d|1\d{2}|[1-9]?\d))?$/;

// Custom validation function for IP list
function validateIPList(ipList) {
  // Split the input by commas and trim whitespace
  const ips = ipList.split(',').map(ip => ip.trim());

  // Validate each IP or IP range
  for (const ip of ips) {
    if (!ipPattern.test(ip)) {
      return `Invalid IP address or range: ${ip}`;
    }
  }
  return true; // All IPs and ranges are valid
}

ajv.addKeyword('ipList', {
  type: 'string',
  errors: true, // Enable custom error messages
  validate: (schema, data) => {
    const result = validateIPList(data);
    if (result !== true) {
      // Custom error message
      ajv.errors = [{ message: result }];
      return false;
    }
    return true;
  }
});

function createValidator(schema) {
  const validator = ajv.compile(schema);
  return (model) => {
    validator(model);
    // Check if consent_to_terms is checked
    if (!model.consent_to_terms) {
      // Add custom error for consent field if unchecked
      validator.errors = validator.errors || [];
      validator.errors.push({
        instancePath: "/consent_to_terms",
        schemaPath: "#/properties/consent_to_terms",
        keyword: "required",
        params: { missingProperty: "consent_to_terms" },
        message: "You must agree to the terms."
      });
    } else {
      // Remove custom error if consent_to_terms is checked
      validator.errors = (validator.errors || []).filter(
        (error) => error.instancePath !== "/consent_to_terms"
      );
    }
    return validator.errors?.length ? { details: validator.errors } : null;
  };
}

function getSchema() {
  const schema = {
    title: "Organization and User Details",
    type: "object",
    properties: {

      organization_name: {
        type: "string", title: "Organization Name",
        uniforms:
        {
          placeholder: "Enter Organization Name"
        }
      },
      organization_domain: {
        type: "string", title: "Organization Domain", format: "uri",
        uniforms:
        {
          placeholder: "Enter Organization Domain"
        }
      },
      email_domain: {
        type: "string", title: "Email Domain", pattern: domainPattern,
        uniforms:
        {
          placeholder: "Enter Email Domain"
        }
      },
      external_ip_ranges: {
        type: "string",
        ipList: true,
        title: "External Internet IP Ranges",
        uniforms:
        {
          placeholder: "Enter IPs or ranges, separated by commas"
        }
      },
      industry_classification: {
        type: "array",
        title: "Industry Classification",
        uniforms:
        {
          placeholder: "Enter Industry Classification"
        },
        items: {
          type: "string",
          enum: [
            "Aerospace", "Consumer Goods", "Defense", "Education", "Engineering",
            "Extractives", "Energy & Utilities", "Financial Services", "Government", "Healthcare",
            "Individuals/NGO", "Hospitality/Leisure", "Manufacturing", "Maritime", "Media/Entertainment",
            "Oil/Gas", "Pharmaceutical", "Professional Services", "Retail", "Technology",
            "Telecommunications", "Transport"
          ]
        },
        uniqueItems: true,
        uniforms: {
          component: CheckboxGroup,
          options: [
            "Aerospace", "Consumer Goods", "Defense", "Education", "Engineering",
            "Extractives", "Energy & Utilities", "Financial Services", "Government", "Healthcare",
            "Individuals/NGO", "Hospitality/Leisure", "Manufacturing", "Maritime", "Media/Entertainment",
            "Oil/Gas", "Pharmaceutical", "Professional Services", "Retail", "Technology",
            "Telecommunications", "Transport"
          ]
        } // Use custom component
      },
      system_user_details: {
        type: "object",
        title: "System User Details",
        properties: {
          name: {
            type: "string", title: "Name",
            uniforms:
            {
              placeholder: "Enter Name"
            },
          },
          email: {
            type: "string", title: "Email", format: "email",
            uniforms:
            {
              placeholder: "Enter Email"
            },
          },
          phone_number: {
            type: "string", title: "Phone Number", pattern: "^[0-9]{10}$",
            uniforms:
            {
              placeholder: "Enter 10-digit Phone Number"
            },
          } // Example pattern for a 10-digit phone number
        },
        required: ["name", "email"]
      },
      consent_to_terms: {
        type: "boolean",
        title: "I agree to the terms and conditions",
      },
    },
    required: ["organization_name", "organization_domain", "industry_classification", "system_user_details"],
  };

  const validator = createValidator(schema);
  return new JSONSchemaBridge({ schema, validator });
}

function CompanyOnboardForn({ model, onSubmit }) {
  return (
    <>
      <h2>Customer Onboard</h2>
      <div className='terms-container'>
        <br /><br />
        <b>Terms and Conditions for TARA Cyber Threat Analytics:</b><br /><br />
        Please read these terms and conditions carefully before granting permission for active scanning. These terms and conditions govern
        your participation in and our conduct of active scanning activities.<br /><br />
        By providing your consent, you agree to be bound by these terms and
        conditions.<br /><br />
        <b>Active Scanning</b> - Active scanning is conducted to identify potential vulnerabilities or security weaknesses within network devices
        and internet-facing systems.<br /><br />
        <b>User Consent</b> - Active scanning will only be performed with the explicit consent of the user or system owner. By granting consent,
        you acknowledge and agree to potential temporary disruptions or inconveniences that may arise during the scanning process.<br /><br />
        <b>Potential Impact</b> - Security Vitals will conduct the scanning swiftly and efficiently, taking into consideration the operational
        requirements of the user or system owner and will make reasonable efforts to minimize any potential disruptions or inconveniences.<br /><br />
        <b>Limitation of Liability</b> - Security Vitals is not liable for any damages, losses, or claims arising from the active scanning process,
        except in cases of gross negligence or willful misconduct.<br /><br />
        <b>Compliance with Applicable Laws</b> - Active scanning is conducted in compliance with all applicable laws, regulations, and ethical
        standards. We will not engage in any activities that may violate the privacy or security rights of individuals or organizations.<br /><br />
        <b>Termination of Active Scanning</b> - The user or system owner has the right to revoke consent for active scanning at any time by
        providing written notice. Upon termination, Security Vitals will cease all active scanning activities and remove any collected
        information from our systems.<br /><br />
        <b>Modification of Terms</b> - Security Vitals reserves the right to modify these terms and conditions at any time provided they are
        communicated to the user or system owner in writing via email prior to implementing the change.<br /><br />
        By granting consent for active scanning, you confirm having read, understood, and agreed to these terms and conditions. If you do not
        agree with any part of these terms and conditions, please do not provide consent. These terms and conditions are effective as of the
        date of granting consent and shall remain in effect until terminated or updated as per the provisions outlined herein.<br /><br />
      </div>
      <br />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <AutoForm
          schema={getSchema()}
          model={model} // Pass the initial data if necessary
          onSubmit={onSubmit} // Handle form submission
          style={{ width: '75%' }}
        />
      </div>
    </>
  );
}

export function CompanyOnboard() {
  const navigate = useNavigate();
  const [isFormVisible, setFormVisible] = useState(true);

  const handleFormSubmit = async (model) => {
    try {
      const { _id, created_at, updated_at, ...rest } = model;
      const data = {
        _id: model._id?.toString() || null,
        topic: "DbOnBoard",
        updates: rest
      };
      const result = await restHandler('PUT', '/api/update-or-insert-document', data);
      notice_success(result.message);
      setFormVisible(false);
      navigate('/admin/menu', { replace: true });
    } catch (err) {
      notice_error(err.message || err.detail || 'Error fetching data');
    } finally {
      //          setFormVisible(false);
      //          navigate('/admin/menu', { replace: true });
    }
  };

  return (
    <>
      <Layout />
      {isFormVisible && (<CompanyOnboardForn model={{}} onSubmit={handleFormSubmit} />)}
    </>);
}

