import React from 'react';
import { connectField } from 'uniforms';

function CheckboxGroup({ value = [], onChange, label, options }) {
    const handleChange = (option) => {
        const newValue = value.includes(option)
            ? value.filter((item) => item !== option) // Remove if already selected
            : [...value, option]; // Add if not selected
        onChange(newValue); // Update the form value
    };

    return (
        <div>
            <label>{label}</label>
            <div className="checkbox-container">
                {options.map((option) => (
                    <label key={option} className="left-cell" style={{ display: 'block', marginBottom: '5px' }}>
                        <input
                            type="checkbox"
                            checked={value.includes(option)}
                            onChange={() => handleChange(option)}
                        />
                        {option}
                    </label>
                ))}
            </div>
            <br/>
        </div>
    );
}

export default connectField(CheckboxGroup);