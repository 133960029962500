import React from 'react';
import Select from 'react-select';
import { connectField } from 'uniforms';

// Custom select options based on the `enum` values in the schema
const options = [
  { value: "Aerospace", label: "Aerospace" },
  { value: "Consumer Goods", label: "Consumer Goods" },
  { value: "Defense", label: "Defense" },
  { value: "Education", label: "Education" },
  { value: "Engineering", label: "Engineering" },
  { value: "Extractives", label: "Extractives" },
  { value: "Energy & Utilities", label: "Energy & Utilities" },
  { value: "Financial Services", label: "Financial Services" },
  { value: "Government", label: "Government" },
  { value: "Healthcare", label: "Healthcare" },
  { value: "Individuals/NGO", label: "Individuals/NGO" },
  { value: "Hospitality/Leisure", label: "Hospitality/Leisure" },
  { value: "Manufacturing", label: "Manufacturing" },
  { value: "Maritime", label: "Maritime" },
  { value: "Media/Entertainment", label: "Media/Entertainment" },
  { value: "Oil/Gas", label: "Oil/Gas" },
  { value: "Pharmaceutical", label: "Pharmaceutical" },
  { value: "Professional Services", label: "Professional Services" },
  { value: "Retail", label: "Retail" },
  { value: "Technology", label: "Technology" },
  { value: "Telecommunications", label: "Telecommunications" },
  { value: "Transport", label: "Transport" }
];

function CompanyIndustrySelectField({ onChange, value }) {
  // Convert selected values to react-select format
  const selectedOptions = value?.map(val => options.find(option => option.value === val)) || [];

  return (
    <>
    <Select
      isMulti
      options={options}
      value={selectedOptions}
      onChange={(selected) => onChange(selected.map(item => item.value))}
      placeholder="Select industries..."
    />
    <br/>
    </>
  );
}

export default connectField(CompanyIndustrySelectField);