import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";

const ConfirmDialog = ({ onConfirm, title, message, trigger }) => {
  const [showModal, setShowModal] = useState(false);

  const handleTriggerClick = (e) => {
    setShowModal(true);
  };

  const onClose = () => {
    setShowModal(false);
  };

  const onConfirmClick = () => {
    onConfirm();
    setShowModal(false);
  };

  return (
    <>
    {React.isValidElement(trigger) && React.cloneElement(trigger, { onClick: handleTriggerClick })}
      <Modal size="xl" show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{message}</Modal.Body>
        <Modal.Footer>
          <Button onClick={onConfirmClick} variant="danger" className="col-sm-2">Yes</Button>
          <Button onClick={onClose} variant="primary" className="col-sm-2">No</Button>
          <Button variant="secondary" onClick={onClose}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>);
}

export default ConfirmDialog;